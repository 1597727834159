<template>
  <div style="margin:30px 60px">
    <el-row :gutter="100">
      <el-col :span="8">
        <div style="text-align:center">
          <h3>客服微信</h3>
          <div>
            <img src="@/assets/kefuQr.png" alt width="200px" />
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <h3>客服工单</h3>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="-1">
            <template slot="title">
              <div style="font-weight:bold;font-size:14px">
                新建工单
                <i class="header-icon el-icon-info"></i>
              </div>
            </template>
            <div>
              <el-input v-model="content" type="textarea" autosize placeholder="请输入..."></el-input>
              <div class="imgeditbox">
                <div v-for="(pic,index) in imgs" :key="index" class="imgwrap">
                  <img :src="baseurl+pic" alt class="imgbox" @click="previewImg(pic)" />
                  <div class="imgdeletewrap" @click="deleteImgConfirm(pic)">
                    <i class="el-icon-delete-solid"></i>
                  </div>
                </div>
                <div v-if="percentage>0" class="imgwrap">
                  <el-progress type="circle" :percentage="percentage"></el-progress>
                </div>
              </div>
              <div style="margin:10px 0">
                <el-upload
                  action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                  :headers="headerObj"
                  :multiple="false"
                  :limit="10"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false"
                  :on-progress="uploadProgress"
                  :on-error="uploadError"
                >
                  <el-button
                    type="text"
                    size="mini"
                    plain
                    :disabled="percentage>0||imgs.length==10"
                  >点击上传图片</el-button>
                  <span
                    v-if="imgs.length==10"
                    style="color:red;margin-left:20px;font-size:12px"
                  >已到图片数量上限</span>
                </el-upload>
              </div>
              <el-button type="primary" size="mini" @click="setNewInfo">提交</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="item.content.slice(0,40)+' ...'"
            :name="index+''"
            v-for="(item,index) in serviceInfo"
            :key="index"
          >
            <template slot="title">
              <div>
                <span v-if="item.state==0" class="replybtn0">待回复</span>
                <span v-else class="replybtn1">已回复</span>
                <span style="margin-left:10px">{{item.content.slice(0,40)}} ...</span>
              </div>
            </template>
            <div style="padding: 10px 30px; background: #fafafa;">
              <div>
                <pre>{{item.content}}</pre>
              </div>
              <div>
                <img
                  v-for="(img,index) in item.imgs"
                  :key="index+'a'"
                  :src="img"
                  alt
                  class="imgbox"
                  @click="previewImg(img)"
                />
              </div>
              <div v-if="item.children.length>0">
                <div v-for="(subItem,index) in item.children" :key="index+'b'" class="childbox">
                  <div>
                    <span v-if="subItem.replyType==0">追问</span>
                    <span v-else style="color: #498df3;">十万八回复</span>
                  </div>
                  <div>
                    <pre>{{subItem.content}}</pre>
                    <div>
                      <img
                        v-for="(img,index) in subItem.imgs"
                        :key="index+'a'"
                        :src="img"
                        alt
                        class="imgbox"
                        @click="previewImg(img)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="childbox flexbtw-g" v-if="!replyShow">
                <el-button type="primary" size="mini" plain @click="setReply">追问</el-button>
                <el-button type="danger" size="mini" plain @click="deleteServiceInfo(item.id)">删除</el-button>
              </div>
              <div v-else>
                <el-input v-model="content" type="textarea" autosize="" placeholder="请输入..."></el-input>
                <div class="imgeditbox">
                  <div v-for="(pic,index) in imgs" :key="index" class="imgwrap">
                    <img :src="baseurl+pic" alt class="imgbox" @click="previewImg(pic)" />
                    <div class="imgdeletewrap" @click="deleteImgConfirm(pic)">
                      <i class="el-icon-delete-solid"></i>
                    </div>
                  </div>
                  <div v-if="percentage>0" class="imgwrap">
                    <el-progress type="circle" :percentage="percentage"></el-progress>
                  </div>
                </div>
                <div style="margin:10px 0">
                  <el-upload
                    action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                    :headers="headerObj"
                    :multiple="false"
                    :limit="10"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :show-file-list="false"
                    :on-progress="uploadProgress"
                    :on-error="uploadError"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      plain
                      :disabled="percentage>0||imgs.length==10"
                    >点击上传图片</el-button>
                    <span
                      v-if="imgs.length==10"
                      style="color:red;margin-left:20px;font-size:12px"
                    >已到图片数量上限</span>
                  </el-upload>
                </div>
                <div>
                  <el-button type="danger" size="mini" @click="setReply">取消</el-button>
                  <el-button type="primary" size="mini" @click="reply(item.id)">提交</el-button>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>

        <div class="paginationwrap-g">
          <el-pagination
            :page-size="count"
            :current-page="page"
            :page-count="9"
            layout="total, prev, pager, next"
            :total="total"
            hide-on-single-page
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="baseurl+imgForPreview" width="100%" alt />
    </el-dialog>
  </div>
</template>

<script>
import {
  getServiceInfoPc,
  deleteFileBiz,
  setServiceInfoPc,
  deleteServiceInfoPc
} from "@/api/api";
export default {
  props: ["type"],
  data() {
    return {
      showImg: false,
      replyShow: false,
      serviceInfo: [],
      total: 0,
      page: 1,
      count: 10,
      activeNames: 0,
      imgForPreview: "",
      baseurl: "https://server.solar960.com",
      headerObj: {
        Accept: "application/x.server.v4+json",
        Authorization: localStorage.getItem("Authorization")
      },
      percentage: 0,
      imgs: [],
      content: ""
    };
  },
  mounted() {
    this.getServiceListPcFn();
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    getServiceListPcFn() {
      const that = this;
      getServiceInfoPc({
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.serviceInfo = res.data.serviceInfo;
          that.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getServiceListPcFn();
    },
    setNewInfo() {
      if (!this.content) {
        this.$message.error("请输入内容");
      } else {
        let params = {
          idUser: this.biz.idUser,
          custType: this.type,
          custId: this.biz.id,
          content: this.content,
          imgs: this.imgs,
          parrentId: 0,
          replyType: ""
        };
        this.submit(params);
      }
    },

    setReply() {
      this.content = "";
      this.imgs.forEach(item => {
        this.deleteFileBizFn(item);
      });
      this.replyShow = !this.replyShow;
    },

    reply(id) {
      if (!this.content) {
        this.$message.error("请输入内容");
      } else {
        let params = {
          idUser: this.biz.idUser,
          custType: this.type,
          custId: this.biz.id,
          content: this.content,
          imgs: this.imgs,
          parrentId: id,
          replyType: 0
        };
        this.submit(params);
      }
    },

    submit(params) {
      const that = this;
      setServiceInfoPc(params).then(res => {
        if (res.result) {
          that.content = "";
          that.imgs = [];
          that.replyShow = false;
          that.$message.success("提交成功");
          that.getServiceListPcFn();
        } else {
          that.$message.error(res.message);
        }
      });
    },

    deleteServiceInfo(id) {
      const that = this;
      this.$confirm("确认删除?", {
        type: "warning"
      })
        .then(() => {
          deleteServiceInfoPc({
            id: id
          }).then(res => {
            if (res.result) {
              that.$message.success("删除成功");
              that.getServiceListPcFn();
            }
          });
        })
        .catch(() => {});
    },

    deleteImgConfirm(file) {
      const that = this;
      if (
        this.editType == "editPosition" &&
        this.editContent.imgs.length == this.editImgNum
      ) {
        this.$alert("图片大于" + this.editImgNum + "张时才可以删除", {
          type: "warning",
          callBack: () => {}
        });
      } else {
        this.$confirm("确认删除？", {
          type: "warning"
        })
          .then(() => {
            that.deleteFileBizFn(file);
          })
          .catch(() => {});
      }
    },
    //删除图片
    deleteFileBizFn(file) {
      const that = this;
      file = file.replace(that.baseurl, "");
      deleteFileBiz({
        file: file
      }).then(res => {
        if (res.result) {
          let index = that.imgs.findIndex(item => {
            return item == file;
          });
          that.imgs.splice(index, 1);
        }
      });
    },
    //上传图片成功
    handleAvatarSuccess(res) {
      if (res.result) {
        let imgUrl = res.data.files;
        this.imgs.push(imgUrl);
      } else {
        this.$message.error(res.message);
      }
      this.percentage = 0;
    },
    //图片上传检查
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },

    //上传进度
    uploadProgress(event) {
      this.percentage = parseInt(event.percent);
    },

    //上传图片错误
    uploadError() {
      this.$message({
        message: "上传失败",
        type: "danger"
      });
      this.percentage = 0;
    },
    //预览图片
    previewImg(item) {
      this.imgForPreview = item.replace(".thumb", "");
      this.showImg = true;
    }
  },
  destroyed() {
    if (this.imgs.length > 0) {
      this.imgs.forEach(item => {
        this.deleteFileBizFn(item);
      });
    }
  }
};
</script>

<style scoped>
.imgbox {
  margin-right: 10px;
  width: 120px;
  height: 120px;
  position: relative;
}
.childbox {
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px dashed #999;
}
.replybtn0 {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 2px 8px;
  font-size: 12px;
}
.replybtn1 {
  border-radius: 5px;
  border: 1px solid mediumseagreen;
  padding: 2px 8px;
  font-size: 12px;
  color: mediumseagreen;
}

.imgeditbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
}

.imgwrap {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  position: relative;
}

.imgdeletewrap {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
  background: #fff;
  padding: 0px 5px;
  border-radius: 100%;
}
h3 {
  text-align: center;
  margin-bottom: 30px;
}
</style>