<template>
  <div>
    <service type="sightCompany" />
  </div>
</template>

<script>
import service from "../components/Service.vue";
export default {
  components: {
    service
  }
};
</script>

<style>
</style>